import React, {useState, useRef, useEffect} from 'react';
import { useLocation } from '@reach/router';

import { graphql, Link, navigate } from 'gatsby'

import SEO from '../components/SEO'
import Navigation from '../components/Navigation';

import '../assets/scss/main.scss'
import '../assets/css/custom.css'

function goTo (id) {
  navigate('/', {
    state: {section: id},
    replace: false
  })
}

function computePageDescription(loc) {
  const MAX=23
  console.log(loc)
  return loc.padStart(loc.length + Math.floor((MAX - loc.length) / 2), ' ')
            .padEnd(MAX, ' ')
}

const NotFoundPage = ( {data} ) => {
  const [clock, setClock] = useState(false);
  const clockRef = useRef(clock);
  clockRef.current = clock;

  // setTimeout(() => {
  //   console.log(clock)
  //   setClock(true)
  //   console.log(clock)
  // }, 1000)
  useEffect(() => {
    setTimeout(() => {
      setClock(true)
    }, 150) 
  }, [])

  const site = data.site.siteMetadata;
  const location = useLocation()
  // const pathname = location.pathname.substr(1)
  var pathname = location.pathname.replace(/\/+$/, "")
  pathname = pathname.replace(/^\/+/, "")

  useEffect(() => {
    if (site.mainPages.indexOf(pathname) != -1) {
      navigate('/', {
        state: {section: pathname},
        replace: true
      })
    }
  }, [])

  const msg = computePageDescription(location.pathname)

  const BANNER = `
\           SORRY           /
 \                         /
  \ ${msg} /
   ] does not exist yet. [    ,'|
   ]                     [   /  |
   ]___               ___[ ,'   |
   ]  ]\             /[  [ |:   |
   ]  ] \           / [  [ |:   |
   ]  ]  ]         [  [  [ |:   |
   ]  ]  ]__     __[  [  [ |:   |
   ]  ]  ] ]\ _ /[ [  [  [ |:   |
   ]  ]  ] ] (#) [ [  [  [ :===='
   ]  ]  ]_].nHn.[_[  [  [
   ]  ]  ]  HHHHH. [  [  [
   ]  ] /   \`HH("N  \ [  [
   ]__]/     HHH  "  \[__[
   ]         NNN         [
   ]         N/"         [
   ]         N H         [
  /          N            \
 /           q,            \
/                           \
`

const BANNER2 = [
  '\\           SORRY            /',
  ' \\                          /',
  '  \\' + msg + '/'
].join('\n')

  return(
  <div className="body is-article-visible">
    <SEO/>
    <div id="wrapper">
    {/* <div id="main" style={clock ? { display: 'flex' } : { display: 'none' }}>  */}
    <div id="main" style={{ display: 'flex' }}> 
    <article
          id="notFound"
          className={clock ? 'active timeout' : ''} 
          style={{ 'width': '90vw' }} 
    >

    {/* <Link to={'/'}>
        <div className="close"> Go back </div>
    </Link> */}
    <h1> NOT FOUND (404) </h1>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
    {/* <p>You just hit <span className="inline-code">{location.pathname}</span> which doesn&#39;t exist... the sadness.</p> */}
    {/* <center> */}
      {/* <pre>
        {BANNER}
      </pre> */}

    <div style={{margin: '0 auto', width: 'fit-content'}}>
    <pre style={{margin: '0px', marginBottom: '1.5rem'}}>{`  
\\                           /
 \\                         /
  \\                       /
   ]                     [    ,'|
   ]                     [   /  |
   ]___               ___[ ,'   |
   ]  ]\\             /[  [ |:   |
   ]  ] \\           / [  [ |:   |
   ]  ]  ]         [  [  [ |:   |
   ]  ]  ]__     __[  [  [ |:   |
   ]  ]  ] ]\\ _ /[ [  [  [ |:   |
   ]  ]  ] ] (#) [ [  [  [ :===='
   ]  ]  ]_].nHn.[_[  [  [
   ]  ]  ]  HHHHH. [  [  [
   ]  ] /   \`HH("N  \\ [  [
   ]__]/     HHH  "  \\[__[
   ]         NNN         [
   ]         N/"         [
   ]         N H         [
  /          N            \\
 /           q,            \\
/                           \\ `}
    </pre>
    </div>
    {/* </center> */}

    <div id="header">
      <Navigation showHome={true} callback={goTo} />
    </div>

    </article>

    </div>
    <div id="bg"></div>
    </div>
  </div>
  )
}

export default NotFoundPage

export const query404 = graphql`
  query Query404 {
    site {
      siteMetadata {
        siteUrl,
        mainPages
      },
    }
  }
`;
