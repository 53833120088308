import React from "react"


const Navigation = props => {
    return <nav>
    <ul>
      { props.showHome && <li>
        <button
          onClick={() => {
            props.callback('')
          }}
        >
          <i class="fa fa-home"></i> Home
        </button>
      </li>}
      <li>
        <button
          onClick={() => {
            props.callback('about')
          }}
        >
          <i class="fa fa-user"></i> About
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.callback('publications')
          }}
        >
          <i class="fa fa-book"></i> Publications
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.callback('software')
          }}
        >
          <i class="fa-solid fa-robot"></i> Software
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.callback('resources')
          }}
        >
          <i class="fa fa-cogs"></i> Resources  
        </button>
      </li>
      {/* <li>
        <button
          onClick={() => {
            props.callback('blog')
          }}
        >
          <i class="fa fa-newspaper"></i> Blog
        </button>
      </li> */}
      <li>
        <button
          onClick={() => {
            props.callback('gallery')
          }}
        >
          <i class="fa fa-image"></i> Gallery  
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            props.callback('contact')
          }}
        >
          <i class="fa fa-envelope"></i> Contact
        </button>
      </li>
    </ul>
  </nav>
}

Navigation.defaultProps = {
    showHome : false,
    callback : (id) => { return }
}

export default Navigation;
